import axiosConnectorInstance from "./AxiosConnector"
import { router } from "../utils/APIUrls"
import { environment, scope } from "../const"
// import { msalInstance } from "../index"
// import moment from "moment"

// const checkTokenExpired = () => {
//   const tokenObject = JSON.parse(localStorage.getItem("accessTokenResponse"))
//   const isTokenExpired = moment(moment()._d).isAfter(moment(tokenObject?.expiresOn)._d)

//   return isTokenExpired
// }

// const getToken = async () => {
//   let tokenObject = JSON.parse(localStorage.getItem("accessTokenResponse"))

//   if (!tokenObject?.idTokenClaims || typeof tokenObject !== "object" || checkTokenExpired()) {
//     const accessTokenRequest = { scopes: [scope], account: msalInstance.getAllAccounts()[0] }

//     await msalInstance.acquireTokenSilent(accessTokenRequest).then(async (accessTokenResponse) => {
//       tokenObject = accessTokenResponse

//       localStorage.setItem("accessTokenResponse", JSON.stringify(accessTokenResponse))
//       localStorage.setItem("token", accessTokenResponse?.accessToken)
//     }).catch((error) => {
//       console.warn(error)
//     })
//   }

//   return tokenObject?.accessToken
// }

export const getCanteenUser = async () => {
  try {
    const res = await axiosConnectorInstance.get(router.getCateringUser, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return { Data: res.data, Status: res.status }
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return { Data: [], Status: error.response.status, Message: error.response.data }
  }
}

export const saveCanteenUser = async (name, email) => {
  const queryData = {
    Name: name,
    Email: email,
  }

  try {
    const res = await axiosConnectorInstance.post(router.getCateringUser, queryData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const deleteCanteenUser = async (email) => {
  try {
    const res = await axiosConnectorInstance.delete(`${router.getCateringUser}/${email}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getCanteenUserProfile = async (email) => {
  const queryData = {
    userId: email,
  }

  try {
    const res = await axiosConnectorInstance.get(router.userImage, {
      params: queryData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const searchColleague = async (query, date) => {
  const queryData = {
    query: query,
    date: date,
  }

  try {
    const res = await axiosConnectorInstance.get(router.searchColleague, {
      params: queryData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getAdminLocation = async () => {
  try {
    const res = await axiosConnectorInstance.get(router.location, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getMeetingRoomByLocation = async (locationId) => {
  try {
    const res = await axiosConnectorInstance.get(`${router.meetingRoomByLocation}?locationId=${locationId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getMeetingByRoom = async (date, meetingId) => {
  const params = {
    date: date,
  }

  try {
    const res = await axiosConnectorInstance.get(`${router.meetingRoomAvailability}/${meetingId}/Meetings`, {
      params: params,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getMeetingData = async (fromDate, toDate) => {
  const params = {
    fromDate: fromDate,
    toDate: toDate,
  }

  try {
    const res = await axiosConnectorInstance.get(router.meetingData, {
      params: params,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getCateringOrderStatus = async () => {
  try {
    const res = await axiosConnectorInstance.get(router.getCateringOrderStatus, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getCateringWithFilter = async (locationIds, statusIds, date, responsible) => {
  const queryData = {
    Responsible: responsible,
    FromDate: date,
    ToDate: date,
    LocationIds: locationIds,
    OrderStatusIds: statusIds,
  }

  try {
    const res = await axiosConnectorInstance.post(router.getCateringWithFilter, queryData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const updateCateringOrderStatus = async (Id, statusId) => {
  try {
    const res = await axiosConnectorInstance.put(`${router.AdminCatering}/${Id}/Status?orderStatusId=${statusId}`, {}, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const updateResponsible = async (Id, ResponsibleIds) => {
  const queryData = {
    Responsible: ResponsibleIds,
  }
  try {
    const res = await axiosConnectorInstance.put(`${router.AdminCatering}/${Id}/Responsible`, queryData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getDepartment = async () => {
  try {
    const res = await axiosConnectorInstance.get(`${router.department}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getCateringOrder = async (orderId) => {
  try {
    const res = await axiosConnectorInstance.get(`${router.AdminCatering}/${orderId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getCateringProduct = async (params) => {
  try {
    const res = await axiosConnectorInstance.get(router.catering, {
      params: params,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getCurrentUserInfo = async () => {
  try {
    const res = await axiosConnectorInstance.get(`${router.currentUser}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const createUpdateOrder = async (queryData) => {
  try {
    const res = await axiosConnectorInstance.put(`${router.AdminCatering}`, queryData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}

export const getCateringFromMeeting = async (meetingId) => {
  try {
    const res = await axiosConnectorInstance.get(`${router.AdminCatering}/${meetingId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res.data
  } catch (error) {
    if (environment) {
      console.warn(error)
    }
    return error.response.data
  }
}
