// export const base_url = "https://rest.mydesk-test.dk/api" //Test
// export const clientId = "dfc7a5bf-7579-4edc-8a76-905bec3ed840" //Test
// export const scope = "https://portlr.onmicrosoft.com/test/user_impersonation" //Test

export const base_url = "https://rest.mydesk.dk/api" //Prod
export const clientId = "ac2195a2-2960-452d-9cb9-ad6e9dfa9e16" //Prod
export const scope = "https://portlr.onmicrosoft.com/user_impersonation" //Prod

export const timeInterval = 60000 // Time Interval for order get

export const environment = process.env.NODE_ENV === "development"

export const localStorageLocationKey = "catering_admin_location"