import React, { useEffect, useRef, useState } from "react"
import { changeDateValue, changeLocationValue, changeStatusValue, changeUserValue } from "../../store/slices/CateringOrderInputStatesSlice"
import { getAdminLocation, getCateringOrderStatus, getCateringWithFilter } from "../../services/ApiServices"
import { changeAllCateringOrderStatusValue, changeAllLocationValue } from "../../store/slices/APIResponseSlice"
import { useDispatch, useSelector } from "react-redux"
import { useReactToPrint } from "react-to-print"
import { timeInterval } from "../../const"
import { toast } from "react-hot-toast"
import { Link } from "react-router-dom"
import ReactDatePicker from "react-datepicker"
import Loader from "../Loader/Loader"
import Select from "react-select"
import moment from "moment"
import "./Overview.css"

// ==== Imported Image ====
import iconUser from "../../assets/img/icon-user.svg"
import iconPin from "../../assets/img/icon-pin.svg"
import iconEdit from "../../assets/img/icon-edit.svg"

export default function Overview() {
    const selectedCanteenUsers = useSelector((state) => state.InputStates.inputUser)
    const selectedLocation = useSelector((state) => state.InputStates.inputLocation)
    const selectedDate = useSelector((state) => state.InputStates.inputDate)
    const selectedOrderStatus = useSelector((state) => state.InputStates.inputStatus)

    const [isLoading, setIsLoading] = useState(false)
    const [filteredData, setFilteredData] = useState()

    const AllCanteenUsers = useSelector((state) => state?.APIResponse?.AllCanteenUsers)
    const AllLocations = useSelector((state) => state?.APIResponse?.AllLocations)
    const AllCateringOrderStatus = useSelector((state) => state?.APIResponse?.AllCateringOrderStatus)

    const dispatch = useDispatch()

    const userRef = useRef()
    const locationRef = useRef()
    const printRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: `Overview_Report_${moment(selectedDate).format("DD_MM_YYYY")}`,
        onAfterPrint: () => { setIsLoading(false) },
        onBeforeGetContent: () => {
            setIsLoading(true)

            return new Promise((resolve) => {
                resolve()
            })
        },
        onBeforePrint: () => { },
        removeAfterPrint: true
    })

    const setInputValueToRedux = () => {
        dispatch(changeUserValue(selectedCanteenUsers))
        dispatch(changeLocationValue(selectedLocation))
        dispatch(changeDateValue(selectedDate))
        dispatch(changeStatusValue(selectedOrderStatus))
    }

    const GetAdminLocation = async () => {
        setIsLoading(true)

        if (AllLocations.length === 0) {
            await getAdminLocation().then((response) => {
                dispatch(changeAllLocationValue(response))
            }).catch((error) => {
                dispatch(changeAllLocationValue([]))
                toast.error(error)
                setIsLoading(false)
            })
        }
    }

    const GetCateringOrderStatus = async () => {
        setIsLoading(true)

        if (AllCateringOrderStatus.length === 0) {
            await getCateringOrderStatus().then((response) => {
                dispatch(changeAllCateringOrderStatusValue(response))
            }).catch((error) => {
                dispatch(changeAllCateringOrderStatusValue([]))
                toast.error(error)
                setIsLoading(false)
            })
        }
    }

    const GetFilteredData = async () => {
        getCateringWithFilter(
            selectedLocation?.map((d) => d?.Id),
            selectedOrderStatus?.map((d) => d?.Id),
            selectedDate,
            selectedCanteenUsers?.value ? selectedCanteenUsers?.value : ""
        ).then(async (response) => {
            setFilteredData(
                response.length > 0
                    ? response?.sort((a, b) => moment(a?.Meeting?.Start).format("HHmm") - moment(b?.Meeting?.Start).format("HHmm"))
                    : []
            )
        }).catch((error) => {
            setFilteredData([])
            toast.error(error)
        })
    }

    // GET ALL ORDERS
    useEffect(() => {
        let subscribed = true
        setIsLoading(true)

        getCateringWithFilter(
            selectedLocation?.map((d) => d?.Id),
            selectedOrderStatus?.map((d) => d?.Id),
            selectedDate,
            selectedCanteenUsers?.value ? selectedCanteenUsers?.value : ""
        ).then(async (response) => {
            if (subscribed) {
                setFilteredData(
                    response && response.length > 0 ?
                        response.sort((a, b) => moment(a?.Meeting?.Start).format("HHmm") - moment(b?.Meeting?.Start).format("HHmm"))
                        : []
                )
            }
        }).catch((error) => {
            if (subscribed) {
                setFilteredData([])
                toast.error(error)
            }
        }).finally(() => {
            setIsLoading(false)
        })

        const interval = setInterval(() => {
            GetFilteredData()
        }, timeInterval)

        return () => {
            clearInterval(interval)
            subscribed = false
            setIsLoading(false)
        }
    }, [selectedLocation, selectedOrderStatus, selectedDate, selectedCanteenUsers])

    useEffect(() => {
        GetAdminLocation()
        GetCateringOrderStatus()
    }, [])

    return (
        <div className="print-fragment" ref={printRef}>
            {isLoading && <Loader />}

            {/* ========================== Header Title ========================== */}
            <div className="pagetitle mb-4 d-flex justify-content-between align-items-center overview-group">
                <h1 className="w-auto">Overview</h1>
                <button
                    onClick={() => handlePrint()}
                    className="btn btn-sm btn-secondary py-1"
                    style={{ minWidth: "0", color: "#013871", borderRadius: "4px" }}
                >
                    <i className="bi bi-printer" /> PRINT REPORT
                </button>
            </div>

            {/* ========================== Input Section ========================== */}
            <div className="section input-section mb-4">
                <div className="row">
                    <div className="col-6 inputSec-icon mb-3">
                        <Select
                            ref={userRef} openMenuOnFocus={true}
                            value={[selectedCanteenUsers?.value ? selectedCanteenUsers : { value: "", label: "Select User" }]}
                            onChange={(e) => dispatch(changeUserValue(e))}
                            options={AllCanteenUsers?.length > 0
                                ? [
                                    { value: "", label: "Select User" },
                                    ...AllCanteenUsers?.map((data) => ({ value: data?.Email, label: data?.Name }))]
                                : [{ value: "", label: "Select User" }]
                            }
                            className="form-control p-0" placeholder="Select User"
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    border: "none",
                                    boxShadow: "none",
                                    padding: "8px 10px",
                                    borderRadius: 8,
                                }),
                            }}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => null
                            }}
                        />
                        <span onClick={() => userRef.current.focus()}>
                            <img className="p-2" alt="icon-user" src={iconUser} />
                        </span>
                    </div>

                    <div className="col-6 inputSec-icon mb-3">
                        <Select
                            ref={locationRef} openMenuOnFocus={true} isMulti
                            value={selectedLocation?.map((location) => ({ value: location?.Id, label: location?.Name }))}
                            onChange={(e) => dispatch(changeLocationValue(e?.map((d) => ({ Id: d?.value, Name: d?.label }))))}
                            className="form-control p-0" placeholder="Select Location"
                            options={AllLocations?.map((location) => ({ value: location?.Id, label: location?.Name }))}
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    border: "none",
                                    boxShadow: "none",
                                    padding: "8px 10px",
                                    borderRadius: 8,
                                }),
                            }}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => null
                            }}
                        />
                        <span>
                            <img className="p-2" alt="icon-pin" src={iconPin} />
                        </span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6 mb-3">
                        <div className="inputSec-icon">
                            <ReactDatePicker
                                type="selectedDate"
                                className="form-control"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd-MM-yyyy"
                                calendarStartDay={1}
                                selected={moment(selectedDate)._d}
                                onChange={(date) => date && dispatch(changeDateValue(moment(date).format("YYYY-MM-DD")))}
                            />
                        </div>
                    </div>

                    <div className="col-6 mb-3">
                        <Select
                            isMulti openMenuOnFocus={true}
                            value={selectedOrderStatus?.map((status) => ({ value: status?.Id, label: status?.Name }))}
                            onChange={(e) => dispatch(changeStatusValue(e?.map((d) => ({ Id: d?.value, Name: d?.label }))))}
                            options={AllCateringOrderStatus?.map((status) => ({ value: status?.Id, label: status?.Name }))}
                            className="form-control p-0" placeholder="Select Status"
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    border: "none",
                                    boxShadow: "none",
                                    padding: "8px 10px",
                                    borderRadius: 8,
                                }),
                            }}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => null
                            }}
                        />
                    </div>
                </div>
            </div>

            {/* ========================== Print Input Details Section ========================== */}
            <div className="section label-section mb-4">
                <div className="row">
                    <div className="col-6 d-inline-flex mb-2">
                        <label className="me-2"><b>User:</b></label>
                        <span>{selectedCanteenUsers?.value ? selectedCanteenUsers?.value : "- -"}</span>
                    </div>
                    <div className="col-6 d-inline-flex mb-2">
                        <label className="me-2"><b>Location:</b></label>
                        <span>{selectedLocation && selectedLocation?.length > 0 ? selectedLocation.map((d) => d?.Name).toString() : "- -"}</span>
                    </div>
                    <div className="col-6 d-inline-flex mb-2">
                        <label className="me-2"><b>Date:</b></label>
                        <span>{moment(selectedDate).format("YYYY-MM-DD")}</span>
                    </div>
                    <div className="col-6 d-inline-flex mb-2">
                        <label className="me-2"><b>Status:</b></label>
                        <span>{selectedOrderStatus && selectedOrderStatus.length > 0 ? selectedOrderStatus.map((d) => d?.Name).toString() : "- -"}</span>
                    </div>
                </div>
            </div>

            {/* ========================== Catering Cards Section ========================== */}
            <section className="overview-section section mb-4">
                {filteredData?.length > 0 &&
                    filteredData?.map((cateringOrder, cateringOrderIndex) =>
                        <div key={cateringOrderIndex} className="card prod-card">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 ">
                                    <div className="row">
                                        <div className="col-lg-8 col-md-8 col-sm-12">
                                            <div className="row">
                                                {/* Time */}
                                                <div className="print-time col-xl-2 col-lg-6 col-md-6 col-sm-6 mb-2">
                                                    <b className="">
                                                        {moment(cateringOrder?.Meeting?.Start).format("HH:mm")}
                                                        {" - "}
                                                        {moment(cateringOrder?.Meeting?.End).format("HH:mm")}
                                                    </b>
                                                </div>

                                                {/* Location(Meeting Room) */}
                                                <div className="print-location col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-2">
                                                    <b>
                                                        {cateringOrder?.Meeting?.MeetingRooms[0] && cateringOrder?.Meeting?.MeetingRooms[0]?.Location?.Name ?
                                                            cateringOrder?.Meeting?.MeetingRooms[0]?.Location?.Name + " (" + cateringOrder?.Meeting?.MeetingRooms[0]?.Name + ")"
                                                            : "- -"}
                                                    </b>
                                                </div>

                                                {/* Order By */}
                                                <div className="print-orderby col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-2">
                                                    <span className="fw-normal">
                                                        <b className="mb-3">Ordered by:&nbsp;</b>
                                                        {cateringOrder?.OrderedByName}
                                                    </span>
                                                </div>

                                                {/* Delivered By */}
                                                <div className="print-deliverby col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-2">
                                                    <span className="fw-normal">
                                                        <b className="mb-3">Delivered by:&nbsp;</b>
                                                        {cateringOrder?.CanteenUsers && cateringOrder?.CanteenUsers?.length > 0 ?
                                                            cateringOrder?.CanteenUsers?.map((user, userIndex) =>
                                                                `${user?.Name}${userIndex === 0 && cateringOrder?.CanteenUsers?.length > 1 ? " ," : ""}`
                                                            )
                                                            : "- -"
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-between gap-2">
                                            {/* Order Status */}
                                            <div className="row print-orderstatus" style={{ flex: "1" }}>
                                                {AllCateringOrderStatus && AllCateringOrderStatus.length > 0 &&
                                                    AllCateringOrderStatus.map((status, statusIndex) =>
                                                        <div key={statusIndex} className="col-xl-4 col-lg-12 col-md-12 col-sm-4 mb-2 d-flex overflow-hidden pe-1">
                                                            <i className={`bi bi-check-circle-fill me-1 ${status?.Id <= cateringOrder?.OrderStatus?.Id ? "text-success" : "text-secondary"}`} />
                                                            <b>{status?.Name}</b>
                                                        </div>
                                                    )}
                                            </div>

                                            {/* Order Edit Icon */}
                                            <div>
                                                <button
                                                    onClick={() => setInputValueToRedux()}
                                                    className="btn btn-secondary btn-icon mb-2 edit-btn"
                                                >
                                                    <Link to={`/order-details/${cateringOrder?.Id}`}>
                                                        <img src={iconEdit} alt="icon-edit" />
                                                    </Link>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12">
                                    <div className="row">
                                        {/* Products */}
                                        <div className="print-products col-xl-2 col-lg-4 col-md-2 col-6 mb-3">
                                            <b className="mb-3">Products</b><br />
                                            {cateringOrder?.ProductOrders && cateringOrder?.ProductOrders?.length > 0 &&
                                                cateringOrder?.ProductOrders?.map((product, index) =>
                                                    <div key={index}>{product?.NumberOfItems} X {product?.Product?.Name}</div>
                                                )
                                            }
                                            {cateringOrder?.ProductOtherOrders && cateringOrder?.ProductOtherOrders?.length > 0 &&
                                                cateringOrder?.ProductOtherOrders?.map((other, index) =>
                                                    <div key={index}>{other?.NumberOfItems} X {other?.Name}</div>
                                                )
                                            }
                                            {!cateringOrder?.ProductOrders && !cateringOrder?.ProductOtherOrders && "- -"}
                                        </div>

                                        {/* Attendees */}
                                        <div className="print-attendee col-xl-3 col-lg-4 col-md-2 col-6 mb-3">
                                            <span className="fw-normal">
                                                <b className="mb-3">Attendees:&nbsp;</b>
                                                {cateringOrder?.NumberOfPeople + cateringOrder?.ExternalAttendeesPeoples}
                                            </span>
                                        </div>

                                        {/* Order Number */}
                                        <div className="print-ordernumber col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-3">
                                            <span className="fw-normal">
                                                <b className="mb-3">Order Number:&nbsp;</b>
                                                {cateringOrder?.OrderNumber ? cateringOrder?.OrderNumber : "- -"}
                                            </span>
                                        </div>

                                        {/* Comments */}
                                        <div className="print-comment col-xl-4 col-lg-12 col-md-4 col-sm-6 mb-3">
                                            <b className="mb-3">Comments</b>
                                            <div>
                                                <pre style={{ fontFamily: "Barlow, serif", fontWeight: "500", whiteSpace: "break-spaces" }}>
                                                    {cateringOrder?.Comment ? cateringOrder?.Comment : "- -"}
                                                </pre>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Internal Comments */}
                                <div className="print-intcomment col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                    <b className="mb-3">Internal Comments</b><br />
                                    <div>
                                        <pre style={{ fontFamily: "Barlow, serif", fontWeight: "500", whiteSpace: "break-spaces" }}>
                                            {cateringOrder?.InternalComment ? cateringOrder?.InternalComment : "- -"}
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </section>

            {/* ========================== Print Catering Cards Section ========================== */}
            <section className="print-overview-section section mb-4">
                {filteredData?.length > 0 &&
                    filteredData?.map((cateringOrder, cateringOrderIndex) =>
                        <div key={cateringOrderIndex} className="card prod-card mb-2">
                            <div className="row d-flex flex-wrap mb-2">
                                {/* Column 1 */}
                                <div className="col-lg-5 col-md-5 col-sm-5">
                                    {/* Order Number */}
                                    <div>
                                        <span className="fw-bold">Order Number: &nbsp;</span>
                                        {cateringOrder?.OrderNumber ? cateringOrder?.OrderNumber : "- -"}
                                    </div>
                                    {/* Attendees */}
                                    <div>
                                        <span className="fw-bold">Attendees: &nbsp;</span>
                                        {cateringOrder?.NumberOfPeople + cateringOrder?.ExternalAttendeesPeoples}
                                    </div>
                                    {/* Meeting Time */}
                                    <div>
                                        <span className="fw-bold">Meeting Time: &nbsp;</span>
                                        {moment(cateringOrder?.Meeting?.Start).format("HH:mm")}
                                        {" - "}
                                        {moment(cateringOrder?.Meeting?.End).format("HH:mm")}
                                    </div>
                                    {/* Status */}
                                    <div>
                                        <span className="fw-bold">Status: &nbsp;</span>
                                        {cateringOrder?.OrderStatus?.Name}
                                    </div>
                                </div>
                                {/* Column 2 */}
                                <div className="col-lg-7 col-md-7 col-sm-7">
                                    {/* Location */}
                                    <div>
                                        <span className="fw-bold">Location: &nbsp;</span>
                                        {cateringOrder?.Meeting?.MeetingRooms[0] && cateringOrder?.Meeting?.MeetingRooms[0]?.Location?.Name ?
                                            cateringOrder?.Meeting?.MeetingRooms[0]?.Location?.Name + " (" + cateringOrder?.Meeting?.MeetingRooms[0]?.Name + ")"
                                            : "- -"}
                                    </div>
                                    {/* Cost Center */}
                                    <div>
                                        <span className="fw-bold">Cost Center: &nbsp;</span>
                                        {cateringOrder?.Department?.Name}{` (${cateringOrder?.Department?.ManagerName})`}
                                    </div>
                                    {/* Delivered By */}
                                    <div>
                                        <span className="fw-bold">Delivered By: &nbsp;</span>
                                        {cateringOrder?.CanteenUsers && cateringOrder?.CanteenUsers?.length > 0 ?
                                            cateringOrder?.CanteenUsers?.map((user, userIndex) =>
                                                `${user?.Name}${userIndex === 0 && cateringOrder?.CanteenUsers?.length > 1 ? " ," : ""}`
                                            )
                                            : "- -"
                                        }
                                    </div>
                                    {/* Ordered By */}
                                    <div>
                                        <span className="fw-bold">Ordered By: &nbsp;</span>
                                        {cateringOrder?.OrderedByName}
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex flex-wrap">
                                {/* Products */}
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <span className="fw-bold">Products: &nbsp;</span>
                                    <div>
                                        {cateringOrder?.ProductOrders && cateringOrder?.ProductOrders?.length > 0 &&
                                            cateringOrder?.ProductOrders?.map((product, index) =>
                                                <div key={index}>{product?.NumberOfItems} X {product?.Product?.Name}</div>
                                            )
                                        }
                                        {cateringOrder?.ProductOtherOrders && cateringOrder?.ProductOtherOrders?.length > 0 &&
                                            cateringOrder?.ProductOtherOrders?.map((other, index) =>
                                                <div key={index}>{other?.NumberOfItems} X {other?.Name}</div>
                                            )
                                        }
                                        {!cateringOrder?.ProductOrders?.length > 0 && !cateringOrder?.ProductOtherOrders?.length > 0 && "- -"}
                                    </div>
                                </div>
                                {/* Comments */}
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <span className="fw-bold">Comments: &nbsp;</span>
                                    <div>
                                        <pre style={{ fontFamily: "Barlow, serif", fontWeight: "500", whiteSpace: "break-spaces" }}>
                                            {cateringOrder?.Comment ? cateringOrder?.Comment : "- -"}
                                        </pre>
                                    </div>
                                </div>
                                {/* Internal Comments */}
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <span className="fw-bold">Internal Comments: &nbsp;</span>
                                    <div>
                                        <pre style={{ fontFamily: "Barlow, serif", fontWeight: "500", whiteSpace: "break-spaces" }}>
                                            {cateringOrder?.InternalComment ? cateringOrder?.InternalComment : "- -"}
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </section>
        </div>
    )
}
